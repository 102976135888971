import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import Particle12 from "../components/Particle12"
import SEO from "../components/SEO"
import "../css/dsa.css"
import { Link } from "gatsby"

const stack = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Stack Programs"
          description="Programs of Stack under Data Structures and Algorithm with Python."
        />
        <Particle12></Particle12>
        <h6>s</h6>
        <section className="noSelect">
          <Link to="/dsa/">
            <button type="button" className="back-btn noSelect">
              <span>{"<"}= More Programs</span>
            </button>
          </Link>
        </section>

        <div className="programming-project">
          <h1>Stack</h1>
        </div>

        <section className="most-liked noSelect">
          <h3 className="total">Total Programs :- 10</h3>
          <div className="row">
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Basic Stack Operations</h3>
                </div>
                <p className="basic-op">
                  •Create a stack
                  <br />
                  •Check if its empty
                  <br />
                  •Push some items
                  <br />
                  •Pop some items
                  <br />
                  •Print the stack
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Basic%20Stack%20Operations.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Representation of a Stack using Static Array
                  </h3>
                </div>

                <p className="basic-op">
                  •Using maxsize from sys module
                  <br />
                  •Create stack with array
                  <br />
                  •Check stack is empty
                  <br />
                  •Push and Pop some items
                  <br />
                  •Print the stack
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Representation%20of%20a%20Stack%20using%20Static%20Array.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Representation of a Stack using Dynamic Array
                  </h3>
                </div>
                <p className="basic-op">
                  •Create nodes of linked list
                  <br />
                  •Check if its empty
                  <br />
                  •Add items
                  <br />
                  •Remove items
                  <br />
                  •Display the stack
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Representation%20of%20a%20Stack%20using%20Dynamic%20Array.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Two stacks in an array</h3>
                </div>
                <p className="basic-op">
                  •Create two stacks
                  <br />
                  •Push separately
                  <br />
                  •Don't forget, size of array has a limit
                  <br />
                  •Pop separately from stacks
                  <br />
                  •Print the stack
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Two%20stacks%20in%20an%20array.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Multiple stack implementation using single array
                  </h3>
                </div>
                <p className="basic-op">
                  •Create required No. of Stacks
                  <br />
                  •Check for empty stacks
                  <br />
                  •Push and pop acordingly
                  <br />
                  •Print the stack
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Multiple%20stack%20implementation%20using%20single%20array.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Reverse a linked list using Stack
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a Linked List
                  <br />
                  •Push some items
                  <br />
                  •Create reverse function setup
                  <br />
                  •Print the reverse stack
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Reverse%20a%20linked%20list%20using%20Stack.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Factorial Calculation using stack
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a stack
                  <br />
                  •Check empty stack
                  <br />
                  •Add some items
                  <br />
                  •Remove some items
                  <br />
                  •Print Factorial of stack
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Factorial%20Calculation%20using%20stack.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Infix to Postfix</h3>
                </div>
                <p className="basic-op">
                  •Remember the Algorithm
                  <br />
                  •Take input for Infix
                  <br />
                  •Give output as Postfix
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Infix%20to%20Postfix.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Postfix to Infix </h3>
                </div>
                <p className="basic-op">
                  •Algorithm is followed
                  <br />
                  •Take input for Postfix
                  <br />
                  •Give output as Infix
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Postfix%20to%20Infix%20.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Towers of Hanoi</h3>
                </div>
                <p className="basic-op">
                  •A mathematical puzzle
                  <br />
                  •Follow simple rules
                  <br />
                  •Accomplish the task
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Stack/Towers%20of%20Hanoi.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default stack
